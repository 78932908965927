function initializeForLettersAnimation(targetElement, className) {
  if (!targetElement || !className) {
    throw new Error();
  }

  targetElement.lettering('lines').children('span').lettering();
  const lines = targetElement[0].childNodes;
  const targetElementLetters = [];
  lines.forEach((line) => {
    const lettersOfEachLine = line.childNodes;
    lettersOfEachLine.forEach((letter) => {
      targetElementLetters.push(letter);
    });
  });
  targetElementLetters.forEach((targetElementLetter) => {
    $(targetElementLetter).addClass('ReverseYZ');
  });

  return targetElementLetters;
}

function animateLetters(
  letters,
  className,
  duration = 100,
  delay = 0,
  callback,
) {
  if (!letters || !className) {
    throw new Error();
  }

  setTimeout(function () {
    letters.forEach((letter, index) => {
      setTimeout(function () {
        $(letter).addClass(className);

        if (callback === undefined) {
          return;
        }

        if (letters.length === index + 1) {
          callback();
        }
      }, duration * index);
    });
  }, delay);
}

function executeFunctionByScroll(targetElement, inviewHeight = 200, callback) {
  if (!targetElement || !callback) {
    throw new Error();
  }

  const scrollY = window.scrollY;
  const windowHeight = window.innerHeight;
  const offsetTop = targetElement.offset().top;
  if (scrollY + windowHeight > offsetTop + inviewHeight) {
    callback();
  }
}
